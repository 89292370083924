import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventMessage } from '../core/messages/event.message';
import { GatewayService } from '../core/services/gateway.service';
import { JsonMapperService } from '../core/services/json-mapper-service';
import { AppSettings, OnOk } from '../core/core';
import { FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit,OnOk {

  private currentRow: any;
  private metada: any;
  
  public elements: any = [];

  constructor(
    private route: ActivatedRoute,
    private eventMessage: EventMessage,
    public app: AppSettings,
    private readonly gatewayService: GatewayService) { }

    private tableName: string;
    private pk: string;
    private action: string;
    private deleteNode: any;

    public appAddButton  : boolean = false;
    public appDeleteButton  : boolean = false;
 



  ngOnInit(): void {

    this.tableName = this.route.snapshot.params['tableName'];

    this.appAddButton = JSON.parse(environment.APP_ADD_BUTTON_ENABLED); 
    this.appDeleteButton = JSON.parse(environment.APP_DELETE_BUTTON_ENABLED);
   
    this.callTable(1,4);


    this.gatewayService.describe(this.tableName).subscribe(result => {
      this.currentRow = JsonMapperService.mapColumnsToJSON(result);
      this.metada = result;     

      
      this.pk = JsonMapperService.extractId(result);
    });


  }

  currentPage: number = 1;
  totalRecords: number;
  pageSize: number;
  totalPages: number;
  pages: string[] = [];


  private callTable(page: number, size:number){

    console.log(page,size);
    
    this.gatewayService.findAll(this.tableName,page,size).subscribe(result => {
      this.elements = result.data; 

      
      
      this.totalRecords = result.pagination.total_records;
      this.pageSize = result.pagination.size_page;
      this.currentPage = result.pagination.current_page;
      this.totalPages = result.pagination.total_pages;

      console.log(this.currentPage);
      
      this.generatePageLinks();
      
    });


  }


  download(){

    this.gatewayService.downloadTable(this.tableName).subscribe(result => { 
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(result);
          downloadLink.setAttribute('download', "report.csv");
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode.removeChild(downloadLink);
        });        
  }


  

  generatePageLinks() {
    this.pages = [] ;
  
    // Number of pages to show before and after the current page
    const pagesToShow = 3;
  
    if (this.totalPages <= 10) {
      for (let i = 1; i <= this.totalPages; i++) {
        this.pages.push(''+i);
      }
    } else {
      if (this.currentPage <= pagesToShow + 1) {
        // Show pages 1 to pagesToShow*2 + 1
        for (let i = 1; i <= pagesToShow * 2 + 1; i++) {
          this.pages.push(''+i);
        }
        this.pages.push('...');
        this.pages.push(''+this.totalPages);
      } else if (this.currentPage >= this.totalPages - pagesToShow) {
        // Show pages totalPages - pagesToShow*2 to totalPages
        this.pages.push(''+1);
        this.pages.push('...');
        for (let i = this.totalPages - pagesToShow * 2; i <= this.totalPages; i++) {
          this.pages.push(''+i);
        }
      } else {
        // Show current page and pages before and after it
        this.pages.push(''+1);
        this.pages.push('...');
        for (let i = this.currentPage - pagesToShow; i <= this.currentPage + pagesToShow; i++) {
          this.pages.push(''+i);
        }
        this.pages.push('...');
        this.pages.push(''+this.totalPages);
      }
    }
  }
  

  goToPage(pageNumber: number) {   
    console.log(pageNumber , this.totalPages); 
    this.callTable(pageNumber,4);
  }

  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  goToNextPage() {
    
    if (this.currentPage < this.totalPages) {
      console.log(this.currentPage + 1 , this.totalPages);
      this.goToPage(this.currentPage + 1);
    }
  }





  openModal(): void {
    this.eventMessage.toggleRowModal('', this.currentRow, this.pk);
  }

  closeModal(): void {
    this.eventMessage.toggleRowModal('', this.currentRow, this.pk);
  }

  selectRow(event: any) {
    this.currentRow = event;
    this.action = 'update';
    this.eventMessage.toggleRowModal(this.action, this.currentRow, this.pk);
  }


  addRow() {
    this.action = 'insert';
    this.eventMessage.toggleRowModal(this.action, this.currentRow, this.pk);
  }

  onSave(row: any) {

    if(this.action == 'update'){
      this.gatewayService.putData(this.tableName, row).subscribe(r => {
        this.eventMessage.toggleRowModal(this.action, this.currentRow, this.pk);
        this.eventMessage.info('updated!');
      });
    }else if(this.action == 'insert'){
      this.gatewayService.insertData(this.tableName, row).subscribe(r => {
        this.eventMessage.toggleRowModal(this.action, this.currentRow, this.pk);
        this.eventMessage.info('updated!');
      });
    }
  }

  onInsert(row: any) {

    this.gatewayService.insertData(this.tableName, row).subscribe(r => {
      this.eventMessage.toggleRowModal('insert', this.currentRow, this.pk);
      this.eventMessage.info('inserted!');
    });
  }

  onDelete(row: any) {
    var name = JsonMapperService.extractId(this.metada);
    var jsonObject = { [name]: row[name] };
    this.deleteNode = jsonObject;
    this.eventMessage.confirm('Are you sure?',this);
  }

  /**
   * This method is called when ok button is pressed in the modal 
   */
  onOk(): void {
    this.gatewayService.deleteData(this.tableName, this.deleteNode).subscribe(r => {
      this.eventMessage.info('saved!');
    });
  }



}
