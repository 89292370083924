import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventMessage } from 'src/app/core/messages/event.message';

@Component({
  selector: 'app-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss']
})
export class TableModalComponent implements OnInit {

  @Input()
  row: any ;

  @Input()
  pk: string ;


  @Output() rowChange: EventEmitter<any> = new EventEmitter<any>();


  constructor(private eventMessage: EventMessage) { }

  ngOnInit(): void {
    this.eventMessage.subscribe('toggleRowModal', callback => {

      this.row = callback.data;  
      this.pk = callback.pk;
      console.log(callback);
      
         
      if (callback.action == 'update') {
        this.isEditable = false;
      } else {
        this.isEditable = true;
      }
      this.toggleModal();
    });
  }

  modalVisible: boolean = false;
  isEditable: boolean = true;



  save() {
    this.rowChange.emit(this.row);
  }

  @ViewChild('modalRow') modalMsg: ElementRef;

  toggleModal() {
    if (this.modalMsg.nativeElement.className === 'hidden' || this.modalMsg.nativeElement.className === 'md:hidden') {
      this.modalMsg.nativeElement.className = ''
    } else {
      this.modalMsg.nativeElement.className = 'hidden'
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    if ( this.isActive() && event.keyCode === 27) {
      this.toggleModal();
    }
    if ( this.isActive() && event.keyCode === 13) {
      this.save();
    }
  }

  isActive(){
    return this.modalMsg.nativeElement.className == '';
  }

}
