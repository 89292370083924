export const environment = {
  production: false,
  //URL_API: 'http://localhost:8080/v1.0',
 URL_API: 'https://app.microservicios.co/api/parametrizer',
 URL_AUTH: 'https://app.microservicios.co/api',
  APP_VERSION: 'develop',
  APP_EVENT_TELEMETRY: 'https://fine-guppy-6804-us1-rest-kafka.upstash.io/webhook?topic=compile&user=ZmluZS1ndXBweS02ODA0JNUMEEhmFqh-Jqeemg5_O2FeS2VUIUdNSyLIzhwdK9c&pass=MmMxNzg5MTEtZTFhYS00NDhhLTk3ZmUtNmQzMzIwYTMyNTg3',
  APP_ADD_BUTTON_ENABLED: 'false',
  APP_DELETE_BUTTON_ENABLED: 'false',
  APP_REPORT_ENABLED: 'true',
  APP_TABLE_ENABLED: 'false',
  APP_LOGO_URL: 'https://7212050.fs1.hubspotusercontent-na1.net/hubfs/7212050/Sitio%20.COM/new-content/nuevo-logo-colsubsidio-2021.svg',
  APP_LOGO_TITLE: 'Colsubsidio caja de compensación familiar',
  APP_PARAMS_TITLE: 'Configurations',
  APP_REPORT_TITLE: 'Reports',
  APP_DOWNLOAD_TITLE: 'Download',
  APP_ADD_TITLE: 'Add',
  APP_THEME_COLOR: 'blue-500',
  
  /* Msal Auth*/
  CLIENT_ID: '5df95a3e-3f5d-4c3a-b8c6-14fa9f8a7f64',
  AUTHORITY_TENANT: '299f82f1-3d57-4f58-9dac-c107bfc6fbbe',
  REDIRECT_URI: 'http://localhost:4200/',
  GRAPH_ENDPOINT: 'https://graph.microsoft.com/v1.0',
  LOGIN_MICROSFOFT:'https://login.microsoftonline.com/',
  AD_ADMIN_ROLE: 'Admin',
  AD_REPORT_ROLE: 'Report',

  /*Google Auth*/
  GOOGLE_CLIENT_ID : '430643588491-kl0qk8n4cp4cmhvqkb3dfnig76naij0m.apps.googleusercontent.com'
  

};
