import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {}

  canActivate() {
      
    // Check if the user is authenticated
    if (this.authService.isAuthenticated()) {      
        
      return true;
    } else {
       
      // Redirect the user to the login page
      this.router.navigate(['/login']);
      return false;
    }
  }

 
}