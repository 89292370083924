import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../core/core';
import { EventMessage } from '../core/messages/event.message';
import { GatewayService } from '../core/services/gateway.service';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

 
  
  constructor(
    private route: ActivatedRoute,
    private eventMessage: EventMessage,
    private router: Router,
    public app: AppSettings,
    private readonly gatewayService: GatewayService) { }


  
   public tables : any = [];


  ngOnInit(): void {
    this.gatewayService.getTables().subscribe(result => {
      this.tables = result;   
     });
  }


  edit(tableName : string){
    //var pk = JsonMapperService.getPrimaryKeyColumnName(this.tables,tableName);
    this.router.navigate(['/table/'+tableName]); 
  }



}
