import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventMessage } from '../core/messages/event.message';
import { GatewayService } from '../core/services/gateway.service';
import { environment } from 'src/environments/environment';
import { AppSettings } from '../core/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  public paramsTitle: string = '';
  public reportTitle: string = '';
  public reportEnabled: any = false;
  public tableEnabled: any = false;
  


  constructor(
    private route: ActivatedRoute,
    private eventMessage: EventMessage,
    public app: AppSettings,
    private readonly gatewayService: GatewayService) { }

  ngAfterViewInit() {
  }
  
  ngOnInit(): void {
    this.paramsTitle = environment.APP_PARAMS_TITLE;
    this.reportTitle = environment.APP_REPORT_TITLE;
    this.tableEnabled = environment.APP_TABLE_ENABLED;
    this.reportEnabled = JSON.parse(environment.APP_REPORT_ENABLED);
  }

}

