import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
  ) { }

  getTables(): Observable<any> {
    return this.http.get<any[]>(`${environment.URL_API}/table`).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  getReports(): Observable<any> {
    return this.http.get<any[]>(`${environment.URL_API}/report`).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  call(reportName: string, page: number, size:number, initDate:string, endDate:string): Observable<any> {

    let queryParams = new HttpParams();

    queryParams = queryParams.append("endDate",endDate);
    queryParams = queryParams.append("initDate",initDate);
    queryParams = queryParams.append("page",''+page);
    queryParams = queryParams.append("size",''+size);
    queryParams = queryParams.append("reportsName",reportName);

 


    return this.http.get<any[]>(`${environment.URL_API}/call`, {  params: queryParams }).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }


  downloadReport(reportName: string, initDate:string ,endDate:string ): Observable<any> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("endDate",endDate);
    queryParams = queryParams.append("initDate",initDate);
    queryParams = queryParams.append("reportsName",reportName);

    return this.http.get(`${environment.URL_API}/download/report`, {  params: queryParams ,responseType: 'blob' }).pipe(
      catchError(e => {       
        return throwError(e);
      })
    )
  }


  downloadTable(reportName: string): Observable<any> {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("tableName",reportName);

    return this.http.get(`${environment.URL_API}/download/table`, {  params: queryParams ,responseType: 'blob' }).pipe(
      catchError(e => {       
        return throwError(e);
      })
    )
  }
  
  

  describe(tableName: string): Observable<any> {
    return this.http.get<any[]>(`${environment.URL_API}/table/describe/`+tableName).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }
  

  findAll(tableName: string, page: number, size:number): Observable<any> {

    let queryParams = new HttpParams();


    queryParams = queryParams.append("page",''+page);
    queryParams = queryParams.append("size",''+size);

    return this.http.get<any[]>(`${environment.URL_API}/table/findAll/`+tableName,{  params: queryParams }).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  insertData(tableName: string, data:any): Observable<any> {
    return this.http.post<any[]>(`${environment.URL_API}/table/insert/`+tableName, data).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  putData(tableName: string, data:any): Observable<any> {
    return this.http.post<any[]>(`${environment.URL_API}/table/update/`+tableName, data).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }

  deleteData(tableName: string, data:any): Observable<any> {
    return this.http.post<any[]>(`${environment.URL_API}/table/delete/`+tableName,data).pipe(
      catchError(e => {
        return throwError(e);
      })
    )
  }


}
