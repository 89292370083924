
import { Injectable } from '@angular/core';



import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthProvider, UserApp } from '../core';
import { LocalStorageService } from './local-storage.service';
import jwtDecode from 'jwt-decode';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root',
})
export class AuthAppProvider implements AuthProvider{
  constructor(private http: HttpClient, private storageService: LocalStorageService) {}

  login(username: string, password: string): Observable<any> {
    var result = this.http.post(
      environment.URL_AUTH + '/auth/signin',
      {
        username,
        password,
      },
      httpOptions
    );
    
    result.subscribe(r => {
      var decoded = jwtDecode(r['access_token']);  
      let user = Object.assign(new UserApp(), decoded);
  
      user.jobTitle = '';
      user.accessToken =r['access_token'];
      this.storageService.saveUser(user);
    });
    return result;
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(
      environment.URL_API + '/auth/signup',
      {
        username,
        email,
        password,
      },
      httpOptions
    );
  }

  logout(): Observable<any> {
    return null;
  }

  isAuthenticated() {
    return this.storageService.isLoggedIn();
  }

  getUser(){
    return this.storageService.getUser();
  }
}
