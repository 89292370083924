<header class="relative bg-white shadow dark:bg-gray-800">
    <nav class="flex items-center justify-between p-6 container mx-auto">
        <!-- Logo -->

        <a href="/">
            <img src="{{app.env.APP_LOGO_URL}}" alt="{{app.env.APP_LOGO_TITLE}}" loading="lazy" width="170" height="43">
        </a>




        <div class="flex items-center">



            <div *ngIf="!name"  class="mr-5 lg:mr-0">
                <a href="#/login" class="font-medium text-indigo-600 hover:text-indigo-500">Log in</a>
            </div>
         
       


            <div *ngIf="name" class="flex items-center md:order-2">
                <button (click)="toggleAvatar()" type="button" class="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                    <div id="dropdownNavbarLink" data-dropdown-toggle="dropdownNavbar" class="bg-{{app.app_theme_color}} relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full ">
                        <span class="font-medium text-white dark:text-gray-300 uppercase">{{name | slice: 0:2 }}</span>
                    </div>
                </button>


                <!-- Dropdown menu -->
                <div *ngIf="isDropDownAvatar" style="position: absolute;        
                margin-top: 20px;
                margin-left: -10px;
                transform: translate3d(6.2px, 58.4px, 0px);" class="z-50  my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600" id="user-dropdown">
        
                  <ul class="py-2" aria-labelledby="user-menu-button">
           
                    <li>
                      <a href="#" (click)="logOut()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign out</a>
                    </li>
                  </ul>
                </div>
                <button data-collapse-toggle="navbar-user" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-user" aria-expanded="false">
                  <span class="sr-only">Open main menu</span>
                  <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                  </svg>
              </button>
            </div>



        </div>

    </nav>
    <div #mobMenu class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <a class="text-gray-800 dark:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                Home
            </a>
        </div>
    </div>



</header>