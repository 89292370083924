
import { Component, OnInit } from '@angular/core';
import { AppSettings, UserApp } from 'src/app/core/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  public name : string;
  public isDropDownAvatar : boolean = false;


  constructor(
    private authService: AuthService,
    public app: AppSettings
  ) { }


  ngOnInit() {
    var userApp = this.authService.getUser();       
     this.name = userApp.name;
  }


  toggleAvatar(){
    this.isDropDownAvatar = !this.isDropDownAvatar;
  }


  logOut(){
    this.authService.logout();
  }



}
