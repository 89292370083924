
import { Injectable } from '@angular/core';



import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthProvider, UserApp } from '../core';
import { LocalStorageService } from './local-storage.service';
import jwtDecode from 'jwt-decode';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { defer, from } from "rxjs";



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root',
})
export class AuthGoogleProvider implements AuthProvider{
  constructor(
    private readonly fb: FormBuilder,
    private storageService: LocalStorageService,
    private authService: SocialAuthService,
    private readonly router: Router  
    ) { }

  login(): Observable<any> {
    var result = from(this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then());
    
    result.subscribe(r =>{

        var user = new UserApp();
        user.name = r['name'];
        user.jobTitle = '';     
        user.provider='google';     
        user.accessToken = r['idToken'];
        console.log(user);
        this.storageService.saveUser(user);
    });

    return  result; 
  }

  register(username: string, email: string, password: string): Observable<any> {
   return null;
  }

  logout(): Observable<any> {
    return null;
  }

  isAuthenticated() {
    return this.storageService.isLoggedIn();
  }

  getUser(){
    return this.storageService.getUser();
  }
}
