import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { AuthProvider, UserApp } from '../core';
import { AuthAppProvider } from './auth.app.provider';
import { AuthMsalProvider } from './auth.msal.service';
import { Router } from '@angular/router';
import { AuthGoogleProvider } from './auth.google.provider';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, 
    private storageService: LocalStorageService,
    private dbAuth : AuthAppProvider,
    private msalAuth : AuthMsalProvider,
    private googleAuth : AuthGoogleProvider,
     private router: Router
    ) {}

  login(username: string, password: string): Observable<any> {
    return this.dbAuth.login(username,password);
  }


  googleLogin(): Observable<any> {
    return this.googleAuth.login();
  }

  msalLogin() : Observable<any> {
    return this.msalAuth.login();
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(
      environment.URL_API + '/auth/signup',
      {
        username,
        email,
        password,
      },
      httpOptions
    );
  }

  logout(): Observable<any> {
    if(this.getUser().provider == 'msal'){
      this.storageService.cleanUser();
    }else if(this.getUser().provider == 'app'){
      this.storageService.cleanUser();
    }else{
      this.storageService.cleanUser();
    }
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    }); 
    return null;
  }

  isAuthenticated() {
    return this.storageService.isLoggedIn();
  }

  getUser(): UserApp{
    return this.storageService.getUser();
  }
}
