import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AppSettings } from '../core/core';
import { GatewayService } from '../core/services/gateway.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',  
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {


  initDate: NgbDateStruct;
  endDate: NgbDateStruct;

  initDateString: string;
  endDateString: string;

  

  public elements: any = [];

  constructor(
    private route: ActivatedRoute,
    public app: AppSettings,
    private readonly gatewayService: GatewayService) { }

    private reportName: string;

    public appAddButton  : boolean = false;
    public appDeleteButton  : boolean = false;



    currentPage: number = 1;
    maxItems: number = 4;
  totalRecords: number;
  pageSize: number;
  totalPages: number;
  pages: string[] = [];



  ngOnInit(): void {

    this.reportName = this.route.snapshot.params['reportName'];
    this.callReport(1,4);
    this.appAddButton = JSON.parse(environment.APP_ADD_BUTTON_ENABLED); 
    this.appDeleteButton = JSON.parse(environment.APP_DELETE_BUTTON_ENABLED);
   
  }

  initDateChanged(newObj) {

    this.initDateString =newObj._inputValue;
    this.callReport(this.currentPage,this.maxItems);
   
}

endDateChanged(newObj) {
 
  this.endDateString = newObj._inputValue;
  this.callReport(this.currentPage,this.maxItems);
}

  private callReport(page: number, size:number){

    if(this.initDateString != null && this.initDateString != ''){
      if(this.endDateString != null && this.endDateString != ''){
    

        this.gatewayService.call(this.reportName,page,size,this.initDateString,this.endDateString).subscribe(result => { 
          this.elements = result.data;   
          
          this.totalRecords = result.pagination.total_records;
          this.pageSize = result.pagination.size_page;
          this.currentPage = result.pagination.current_page;
          this.totalPages = result.pagination.total_pages;
          this.generatePageLinks();
    
         });

      }
    }
    
    
  }





  generatePageLinks() {
    this.pages = [] ;
  
    // Number of pages to show before and after the current page
    const pagesToShow = 3;
  
    if (this.totalPages <= 10) {
      for (let i = 1; i <= this.totalPages; i++) {
        this.pages.push(''+i);
      }
    } else {
      if (this.currentPage <= pagesToShow + 1) {
        // Show pages 1 to pagesToShow*2 + 1
        for (let i = 1; i <= pagesToShow * 2 + 1; i++) {
          this.pages.push(''+i);
        }
        this.pages.push('...');
        this.pages.push(''+this.totalPages);
      } else if (this.currentPage >= this.totalPages - pagesToShow) {
        // Show pages totalPages - pagesToShow*2 to totalPages
        this.pages.push(''+1);
        this.pages.push('...');
        for (let i = this.totalPages - pagesToShow * 2; i <= this.totalPages; i++) {
          this.pages.push(''+i);
        }
      } else {
        // Show current page and pages before and after it
        this.pages.push(''+1);
        this.pages.push('...');
        for (let i = this.currentPage - pagesToShow; i <= this.currentPage + pagesToShow; i++) {
          this.pages.push(''+i);
        }
        this.pages.push('...');
        this.pages.push(''+this.totalPages);
      }
    }
  }
  

  goToPage(pageNumber: number) {
    this.callReport(pageNumber,4);
  }

  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.goToPage(this.currentPage - 1);
    }
  }

  goToNextPage() {
    if (this.currentPage < this.totalPages) {
      this.goToPage(this.currentPage + 1);
    }
  }


  download(){

    
    if(this.initDateString != null && this.initDateString != '' && this.endDateString != null && this.endDateString != ''){
      
        this.gatewayService.downloadReport(this.reportName, this.initDateString, this.endDateString).subscribe(result => { 
              let downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(result);
              downloadLink.setAttribute('download', "report.csv");
              document.body.appendChild(downloadLink);
              downloadLink.click();
              downloadLink.parentNode.removeChild(downloadLink);
            });        
      
      }
  }







}
