import { CommonModule, } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { TableComponent } from './table/table.component';
import { TablesComponent } from './tables/tables.component';
import { HomeComponent } from './home/home.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportComponent } from './report/report.component';
import { AuthGuard } from './core/guard/auth-guard';

const routes: Routes =[

    { path: 'tables',          component: TablesComponent   ,canActivate: [AuthGuard] ,data: { breadCrumb: "Tables"}},  
    { path: 'table/:tableName',          component: TableComponent  ,canActivate: [AuthGuard] ,data: { breadCrumb: ""} },  
    
    { path: 'reports',          component: ReportsComponent   ,canActivate: [AuthGuard] ,data: { breadCrumb: "Reports"}},  
    { path: 'report/:reportName',          component: ReportComponent  ,canActivate: [AuthGuard] ,data: { breadCrumb: ""} },
    
    {
      path: 'login',
      component: LoginComponent,
      pathMatch: 'full'
    },
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full'
    },
    { path: '**', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [],
})
export class AppRoutingModule { }


