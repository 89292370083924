
import { Inject, Injectable } from '@angular/core';

import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';

import { filter } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthProvider, UserApp } from '../core';
import { LocalStorageService } from './local-storage.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthMsalProvider implements AuthProvider {
  constructor(private http: HttpClient
    , private storageService: LocalStorageService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,) { }

  login(): Observable<AuthenticationResult> {

    if (this.msalGuardConfig.authRequest) {
      var r = this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest);
          r.subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.initLogin();
          });
      return r;

      
    } else {
      var r =  this.authService.loginPopup();
          r.subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.initLogin();
        });
        return r;
    }
  }

  private setLoginDisplay() {
      this.http.get(environment.GRAPH_ENDPOINT+'/me')
        .subscribe(profile => {  
          var user = new UserApp();
          user.name = profile['displayName'];          
          user.jobTitle = profile['jobTitle'];     
          user.provider='msal';     
          this.storageService.saveUser(user);
        });
  
  }


  logout(): Observable<any> {
    return this.http.post(environment.URL_API + '/auth/signout', {}, httpOptions);
  }

  isAuthenticated() {
    return this.storageService.isLoggedIn();
  }

  getUser() {
    return this.storageService.getUser();
  }


  private initLogin() {

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(r=> {
        this.setLoginDisplay();
      })
  }
}
