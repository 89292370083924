import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { LocalStorageService } from '../core/services/local-storage.service';

const PROVIDER_KEY = 'authProvider';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(private authService: AuthService, private router: Router, private storage: LocalStorageService ) { }

  ngOnInit(): void {

    if (this.authService.isAuthenticated()) {
      this.isLoggedIn = true;
      this.loggedIn();
    }
  }

  onSubmit(): void {

    this.storage.setItem(PROVIDER_KEY, 'Own');
    const { username, password } = this.form;

    this.authService.login(username, password).subscribe({
      next: data => {
        this.loggedIn();
      },
      error: err => {
        console.log(err);
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    });
  }

  msalLogin() {
    this.authService.msalLogin().subscribe(
      r => {
        this.loggedIn();
      }
    );
  }



  googleLogin() {
    this.storage.setItem(PROVIDER_KEY, 'Google');
    this.authService.googleLogin().subscribe(
      r => {       
       this.loggedIn();
      }
    );
  }


  private loggedIn() {
    this.isLoginFailed = false;
    this.isLoggedIn = true;

      this.router.navigate(['/']).then(() => {
        window.location.reload();
      }); 

  }

}

