import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/core/core';
import { filter } from "rxjs/operators";
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  public breadcrumb: Breadcrumb[] = [];

  constructor(private route: ActivatedRoute,

    private router: Router) { }

  ngOnInit() {
    this.buildBreadcrumb();
  }

  buildBreadcrumb() {
    this.breadcrumb = [];
    const tableId =  this.route.snapshot.params['tableName'];
    const reportId =  this.route.snapshot.params['reportName'];

    var tableName = this.route.snapshot.params['tableName'];

    console.log(tableId,reportId,tableName);
    

    
    this.router.events
    .pipe(
       filter(event => event instanceof NavigationEnd)).subscribe(event => {
        let currentRoute = this.route.root,
                  url = "";
            do {
          const childrenRoutes = currentRoute.children; 
          currentRoute = null;
          childrenRoutes.forEach(route => {

          if (route.outlet === "primary") {
            const routeSnapshot = route.snapshot;

            
            if(route.snapshot.data.breadCrumb != undefined){
              url += "/" + routeSnapshot.url.map(segment => segment.path).join("/");
              if(route.snapshot.params.tableName != undefined){
                this.breadcrumb.push(new Breadcrumb( route.snapshot.params.tableName,url));
              }else if(route.snapshot.params.reportName != undefined){
                this.breadcrumb.push(new Breadcrumb( route.snapshot.params.reportName,url));
              }else{
                this.breadcrumb.push(new Breadcrumb( route.snapshot.data.breadCrumb,url));
              }
            
            }
            
            // 
            //currentRoute = route;
          }
          });
          } while (currentRoute);
        });


   /* if(tableId != undefined){
      this.breadcrumb.push(new Breadcrumb('Tables','/table'));
      this.breadcrumb.push(new Breadcrumb('Batch Job Execution Context','/table/'+tableId));

    }*/
    
    
  }

}