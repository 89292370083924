import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../core/core';
import { EventMessage } from '../core/messages/event.message';
import { GatewayService } from '../core/services/gateway.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  
  
  constructor(
    private route: ActivatedRoute,
    private eventMessage: EventMessage,
    private router: Router,
    public app: AppSettings,
    private readonly gatewayService: GatewayService) { }


  
   public reports : any = [];


  ngOnInit(): void {
    this.gatewayService.getReports().subscribe(result => {
      this.reports = result;   
      console.log(result);      
     });
  }


  view(reportName : string){
    this.router.navigate(['/report/'+reportName]); 
  }




}
