<section class="text-gray-700 body-font">
    <div class="container px-5 py-24 mx-auto">

        <div class="grid grid-cols-3 gap-6">

            <a *ngIf="tableEnabled == true" href="#/tables" class="cursor-pointer w-48 h-48 bg-white shadow-md rounded-md flex justify-center items-center hover:no-underline">
                <!-- Card Content -->
                <div class="px-4 py-2 text-center">
                  <!-- Title -->
                  <h2 class="text-2xl font-semibold text-blue-600">{{paramsTitle}}</h2>
                </div>
            </a>

            <a *ngIf="reportEnabled == true" href="#/reports" class="cursor-pointer w-48 h-48 bg-white shadow-md rounded-md flex justify-center items-center hover:no-underline">
                <!-- Card Content -->
                <div class="px-4 py-2 text-center">
                  <!-- Title without underline -->
                  <h2 class="text-2xl font-semibold text-blue-600">{{reportTitle}}</h2>
                </div>
              </a>

        </div>
    </div>
</section>