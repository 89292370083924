import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EventInstrumental {

    constructor(
        private http: HttpClient,
    ) { }

    sendEvent(data: any) {

        this.http.post(environment.APP_EVENT_TELEMETRY
            , data).subscribe(r => {
               // console.log(r);
            });
    }


    sendRequest(user: string, data: any) {

        this.http.post(environment.APP_EVENT_TELEMETRY
            , {user:user, data:data}).subscribe(r => {
                //console.log(r);
            });
            
    }



}

